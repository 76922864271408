<template>
  <a-modal
    wrap-class-name="user-leave-modal"
    :visible="true"
    :closable="false"
    :mask-closable="false"
  >
    <div style="text-align: center; margin-top: -48px;">
      <img style="width: 100%" src="~@/assets/images/account-locked.png">
      <h2>{{ $t('common_text_055') }}</h2>
      <div>{{ $t('common_text_056') }}</div>
    </div>
    <template #footer>
      <div style="text-align: center; padding-bottom: 24px">
        <a-button
          type="primary"
          shape="round"
          style="min-width: 138px"
          @click="linkBack"
        >{{ $t('common_text_057') }}</a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
export default {
  name: 'UserLeave',
  setup () {
    const router = useRouter()
    const store = useStore()
    const linkBack = () => {
      const userInfo = localStorage.getItem('userInfo')
      if (userInfo) store.commit('updateUserInfo', JSON.parse(userInfo))
      router.back()
    }
    return {
      linkBack
    }
  }
}
</script>

<style lang="less">
.user-leave-modal {
  background: #fff;
  .ant-modal-header, .ant-modal-footer {
    border: none;
  }
}
</style>

